import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './App.css';
import { AuthProvider } from './contexts/AuthContext';
import Login from './components/Login';

const localizer = momentLocalizer(moment);

function App() {
    const [events, setEvents] = useState([]);
    const [todos, setTodos] = useState([]);
    const [newTodo, setNewTodo] = useState('');

    const handleAddEvent = (slotInfo) => {
        const title = window.prompt('Enter event title:');
        if (title) {
            setEvents([...events, { start: slotInfo.start, end: slotInfo.end, title }]);
        }
    };

    const handleAddTodo = (e) => {
        e.preventDefault();
        if (newTodo.trim()) {
            setTodos([...todos, { id: Date.now(), text: newTodo, completed: false }]);
            setNewTodo('');
        }
    };

    const toggleTodo = (id) => {
        setTodos(todos.map(todo =>
            todo.id === id ? { ...todo, completed: !todo.completed } : todo
        ));
    };

    return (
        <AuthProvider>
            <div className="app">
                <Login />
                <div className="calendar">
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: '100%' }}
                        onSelectSlot={handleAddEvent}
                        selectable
                    />
                </div>
                <div className="todo-list">
                    <h2>Todo List</h2>
                    <form onSubmit={handleAddTodo}>
                        <input
                            type="text"
                            value={newTodo}
                            onChange={(e) => setNewTodo(e.target.value)}
                            placeholder="Add new todo"
                        />
                        <button type="submit">Add</button>
                    </form>
                    <ul>
                        {todos.map(todo => (
                            <li key={todo.id} onClick={() => toggleTodo(todo.id)} style={{ textDecoration: todo.completed ? 'line-through' : 'none' }}>
                                {todo.text}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </AuthProvider>
    );
}

export default App;