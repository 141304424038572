import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token) {
            const decodedToken = jwtDecode(token);
            if (decodedToken.exp * 1000 > Date.now()) {
                setUser(decodedToken);
            } else {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
            }
        }
    }, []);

    const login = async (email, authCode) => {
        try {
            const response = await axios.post('http://localhost:8000/api/auth/verify-auth-code/', { email, auth_code: authCode });
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            setUser(jwtDecode(response.data.access));
        } catch (error) {
            console.error('Login failed:', error);
            throw error;
        }
    };

    const logout = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        setUser(null);
    };

    const requestAuthCode = async (email) => {
        try {
            await axios.post('http://localhost:8000/api/auth/request-auth-code/', { email });
        } catch (error) {
            console.error('Failed to request auth code:', error);
            throw error;
        }
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, requestAuthCode }}>
            {children}
        </AuthContext.Provider>
    );
};