import React, { useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

function Login() {
    const [email, setEmail] = useState('');
    const [authCode, setAuthCode] = useState('');
    const [step, setStep] = useState('email');
    const { user, login, logout, requestAuthCode } = useContext(AuthContext);

    const handleRequestCode = async (e) => {
        e.preventDefault();
        try {
            await requestAuthCode(email);
            setStep('authCode');
        } catch (error) {
            console.error('Failed to request auth code:', error);
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await login(email, authCode);
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    if (user) {
        return (
            <div>
                <p>Welcome, {user.email}!</p>
                <button onClick={logout}>Logout</button>
            </div>
        );
    }

    return (
        <div>
            {step === 'email' ? (
                <form onSubmit={handleRequestCode}>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        required
                    />
                    <button type="submit">Request Auth Code</button>
                </form>
            ) : (
                <form onSubmit={handleLogin}>
                    <input
                        type="text"
                        value={authCode}
                        onChange={(e) => setAuthCode(e.target.value)}
                        placeholder="Enter auth code"
                        required
                    />
                    <button type="submit">Login</button>
                </form>
            )}
        </div>
    );
}

export default Login;